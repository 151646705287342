<script setup>
import Checkbox from '@/Components/Checkbox.vue'
import GuestLayout from '@/App/Layouts/GuestLayout.vue'
import Head from '@/Components/Head.vue'
import { Link, useForm } from '@inertiajs/vue3'
import InputGroup from '@/Components/InputGroup.vue'
import Button from '@/Components/Button.vue'

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
})

const form = useForm({
    email: '',
    password: '',
    remember: false,
})

const submit = () => {
    form.post(route('app.login'), {
        onFinish: () => form.reset('password'),
    })
}
</script>

<template>
    <GuestLayout>
        <Head
            title="Log in"
            description="Experience the power of vidfaceswap for effortless AI face swaps and deepfakes with flexible credit packages."
            :url="route('app.login')"
            :canonical="route('app.login')"
            :image="{
                url: '/images/og-068742z3inr9.webp',
                type: 'image/webp',
                width: 1200,
                height: 630,
                alt: 'vidfaceswap',
            }"
        />

        <div v-if="status" class="mb-4 text-sm font-medium text-green-400">
            {{ status }}
        </div>

        <form @submit.prevent="submit" class="space-y-4">
            <InputGroup
                v-model="form.email"
                placeholder="Email address"
                required
                type="email"
                autocomplete="username"
                :error="form.errors.email"
            />

            <InputGroup
                v-model="form.password"
                placeholder="Password"
                type="password"
                required
                autocomplete="current-password"
                :error="form.errors.password"
            />

            <Checkbox v-model:checked="form.remember" label="Remember me" />

            <Button
                type="submit"
                class="w-full"
                :class="{ 'opacity-25': form.processing }"
                size="xl"
                :disabled="form.processing"
            >
                Log in
            </Button>

            <div class="text-center">
                <div>
                    <Link
                        :href="route('app.register')"
                        class="rounded-md text-sm text-gray-400 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                        Don't have an account yet?
                    </Link>
                </div>

                <div v-if="canResetPassword">
                    <Link
                        :href="route('app.password.request')"
                        class="rounded-md text-sm text-gray-400 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                        Forgot your password?
                    </Link>
                </div>
            </div>
        </form>
    </GuestLayout>
</template>
