<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:checked'])

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    value: {
        default: null,
    },
})

const proxyChecked = computed({
    get() {
        return props.checked
    },

    set(val) {
        emit('update:checked', val)
    },
})
</script>

<template>
    <label class="flex items-center">
        <input
            type="checkbox"
            :value="value"
            v-model="proxyChecked"
            class="h-4 w-4 rounded border-gray-700 bg-white/5 text-indigo-600 focus:ring-indigo-600"
        />
        <span v-if="label" class="ml-2 text-sm text-gray-300">{{ label }}</span>
    </label>
</template>
